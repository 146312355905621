

#zj_image_editor {
    width: 100%;
    padding: 1em;
    background-color: antiquewhite;
}

#zj_image_uploader_container {
    width: 100%;
}

#zj_image_selector {
    max-width: 500px;
    text-align: left;
    background-color: rgb(196, 196, 235);
    padding: 10px;
}

#zj_image_previewer_container {
    width: 100%;
    display: flex;
    align-content: left;
    padding: 2em 5em;
}

#zj_image_uploader_input {
    background-color: azure;
}

.zj_preview_single_image_container img {
    display: block;
    width: 100px;
    height: auto;
    padding: 0 2px;
}

