
.user_login_tip_info {
    display: inline-block;
    font-size: 0.16rem;
    width: 1rem;
    text-align: center;
}

.user_login_user_input {
    display: inline-block;
    font-size: 0.19rem;
    width: 2rem;
    padding: 0 0.05rem;
    color: rgb(18, 46, 185);
}

.user_login_line_div {
    height: 0.3rem;
    width: 3.2rem;
    margin: 0 auto;
}

.invite_login_input {
    border: solid rgb(188, 188, 212) 1px;
    padding: 0.02rem;
    width: 50%;
    line-height: 0.23rem;
}

.user_login_button {
    width: 2.5rem;
    height: 0.35rem;
    display: block;
    margin: 0.1rem auto auto auto;
    border-radius: 0.15rem;
    border: 1px solid rgb(143, 143, 231);
    color: #222222;
    background-color: #EEEEEE;
    font-size: 0.13rem;
}

.user_login_button:hover {
    background-color: cadetblue;
    color: white;
}

.login_center_content_div {
    background-color: rgb(250, 243, 235);
    width: 3.2rem;
    height: 1rem;
    margin: 5% auto 5% auto;
    padding: 0.2rem 0.2rem;
    text-align: center;
}

.invite_button {
    font-weight: 500;
    color: brown;
    height: 0.36rem;
}
