#lc_website_info_bar {
    background-color: #f6fafaf9;
    color: #908f8f;
    padding: 0.1rem 0.1rem;
    width: 100%;
    clear: both;
}

#lc_website_info_bar p {
    font-size: 0.11rem;
    text-align: center;
    line-height: 0.12rem;
    padding: 0.08rem;
    margin: 0rem;
    font-weight: 500;
}

#lc_website_info_bar a {
    text-decoration: none;
    color: #908f8f;
}
