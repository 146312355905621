

.app_home_page_top_carousel_computor {
    width: 768px;
    aspect-ratio: 768/550;
    background-color: green;
    overflow: hidden;
}

.app_home_page_top_carousel_mobile img {
    width: 100%;
    /* aspect-ratio: 768/550; */
    background-color: antiquewhite;
}

.app_home_page_top_carousel_computor {
    margin: 0 auto;
}

.app_home_page_top_carousel_computor img {
    display: block;
    margin: 0 auto;
    object-fit:cover;
    aspect-ratio: 768/350;
    background-color: antiquewhite;
}