
#zj_phone_navigation_bar_container {
    position: fixed;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: orange;
}

.zj_phone_navigation_bar_item_common {
    flex:0.333;
    font-size: 0.3rem;
    height: 0.6rem;

    /* 内部的文字上下居中 */
    display: flex;
    justify-content: center;
    align-items: center;
}

.zj_phone_navigation_bar_item_normal {
    background-color: rgb(248, 252, 248);
    color: black;
}

.zj_phone_navigation_bar_item_selected {
    background-color: rgb(40, 149, 40);
    color: white;
}

.zj_phone_navigation_bar_item_common a {
    display: block;
    text-decoration: none;
}

.zj_phone_navigation_bar_item_normal a {
    color: black;
}

.zj_phone_navigation_bar_item_selected a {
    color: white;
}

.zj_phone_navigation_bar_item_common a:hover {
    font-weight: bold;
    background-color: none;
}

