

#mobile_device_content_container {
    position: relative;
    height:100vh;
    width: 100vw;
    background-color: white;
}

#phone_main_content_container {
    height:calc(100vh - 0.6rem);
    overflow: auto;
}

#computor_device_content_container {
    width: 9rem;
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
}