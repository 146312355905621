#lc_python_editor_container {
    font-size: 0.15rem;
}

.code_mirror_highlight_line {
    background-color: rgb(113, 25, 25);
}

.lc_playground_perform_state_div {
    color: rgb(10, 121, 106);
}

.lc_playground_success_result_div {
    color: rgb(5, 99, 5);
}

.lc_playground_fail_result_div {
    color: rgb(164, 3, 3);
}