#all_class_page {
    display        : -webkit-flex;
    /* Safari */
    display        : flex;
    flex-direction : row;
    flex-wrap      : wrap;
    justify-content: center;
    font-size      : 0.25rem;
    font-weight    : 500;
    color          : #222222;
}

#all_class_page :hover {
    font-weight: 500;
    color      : rgb(80, 104, 100);
}

.each_lesson_div_phone {
    width           : 46%;
    aspect-ratio    : 1;
    background-color: rgb(201, 241, 228);
    margin          : 1%;
    position        : relative;
}

.each_lesson_div_computor {
    width           : 46%;
    aspect-ratio    : 1/0.68;
    background-color: rgb(201, 241, 228);
    margin          : 1%;
    position        : relative;
}

.lc_text_bg {
    width: 100%;
    height: 0.35rem;
    background-color: rgb(217, 252, 223);
    position: absolute;
    opacity: 0.8;
}

#all_class_page a {
    display        : block;
    width          : 100%;
    height         : 100%;
    text-decoration: none;
    position: absolute;
    padding-left: 0.2rem;
}

.last_lesson_div_place_holder {
    opacity: 0.05;
}

.typing_practice_div {
    background-image   : url("https://hongxinyouzi.com/learncoding/app_lesson_typing_lesson_bg.jpg");
    background-size    : cover;
    /* 适配整个元素大小，保持宽高比 */
    background-position: center;
    /* 背景图片居中显示 */
    background-repeat  : no-repeat;
}

.scratch_lession_div {
    background-image   : url("https://hongxinyouzi.com/learncoding/app_lesson_scratch_lesson_bg.jpg");
    background-size    : cover;
    /* 适配整个元素大小，保持宽高比 */
    background-position: center;
    /* 背景图片居中显示 */
    background-repeat  : no-repeat;
}