

#zj_douyin_player {
    position:fixed;
    left: 0;
    top:0.9rem;
    width: 100%;
    height: 90%;
    background-color: rgb(80, 80, 80);
}

#zj_douyin_player_top_button_bg {
    left: 0;
    top:-0.9rem;
    width: 100%;
    height: 0.9rem;
    background-color:  rgb(80, 80, 80);
    position: absolute;
}

#zj_douyin_player_content {
    width: 100%;
    height: 100%;
}

#player_inner_frame {
    position: absolute;
    margin: 0 auto;
}

#player_inner_buttons_div {
    position: absolute;
}

#zj_douyin_iframe_container {
    position: absolute;
    background-color: rgb(203, 229, 214);
}

#player_inner_buttons_div {
    width: 2.5rem;
    height: 0.8rem;
    left: 2%;
    top: -0.85rem;
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    color: white;
    font-weight: 500;
}
#player_inner_buttons_div:hover {
    font-weight: 800;
    color: rgb(172, 235, 212);
}

#player_inner_buttons_div_bg{
    background-color: rgb(21, 22, 21);
    border-radius: 0.1rem;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}

#player_inner_buttons_div #close_tip {
    position: absolute;
    top: 0.25rem;
    left: 1rem;
    font-size: 0.18rem;
    text-align: center;
}


#player_inner_buttons_div #close_icon {
    position: absolute;
    top: 0.08rem;
    left: 0.36rem;
    font-size: 0.5rem;
    text-align: center;
}