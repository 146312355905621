
#zj_text_editor {
    width: 100%;
    padding: 5px 0;
    background-color: #EEEEEE;
    border: #AA0000 1px solid;
    display: flex;
    justify-content: center;
    font-size: 19px;
}

#zj_text_area {
    display: block;
    height: 500px;
    border: 0px;
    text-decoration: none;
    resize: none;
}

#zj_text_area:hover {
    border: 0px;
}

#zj_editor_preview {
    width: 50%;;
}

.zj_editor_image_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

#zj_editor_preview img {
    display: block;
}

#zj_editor_preview video {
    display: block;
}

.zj_editor_inner_image {
    width: 60%;
    height: auto;
}

.zj_editor_video_douyin_container {
    margin: 0 auto;
    width: "100%";
    aspect-ratio: '16.0/9.0';
}

.zj_editor_video_blibili_container {
    margin: 0 auto;
    width: "100%";
    aspect-ratio: '16.0/9.0';
}

.zj_editor_douyin_video_iframe {
    margin: auto auto;
}
