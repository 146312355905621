

#to_login_bg_div {
    position       : fixed;
    width          : 100%;
    height         : 100%;
    background-color: rgb(189, 222, 242);
    /* backdrop-filter: blur(15px);
    color          : #e7b2b2;
    box-shadow     : 0 0 30px 10px rgba(0, 0, 0, .3); */
  }
  
  #to_login_front_bg_div {
    width           : 350px;
    height          : 100px;
    border-radius   : 10px;
    background-color: rgb(242, 234, 220);
    margin          : 10% auto auto auto;
    padding: 0.2rem;
  }
  
  .to_login_front_bg_div_inner_div a {
    display: block;
    text-align: center;
    width: 100%;
    line-height: 0.3rem;
    text-decoration: none;
    font-size: 0.2rem;
    color    : rgb(5, 106, 5);
  }

  .to_login_front_bg_div_inner_div .just_to_login_page_left_time {
    text-decoration: none;
    color: rgb(191, 17, 17);
    font-size: 0.3rem;
  }