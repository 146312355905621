* {
    padding                    : 0px;
    margin                     : 0px;
    /* a标签点击的时候有蓝色的背景 */
    -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 320px) {
    html {
        font-size: 90px;

    }
}

@media screen and (min-width: 370px) {
    html {
        font-size: 105px;
    }
}

@media screen and (min-width: 1200px) {
    html {
        font-size: 125px;
    }
}