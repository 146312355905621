

#article_content_page {
    padding: 0 0.1rem;
    line-height: 0.25rem;
    background-color: rgb(249, 248, 241);
}
#article_content_page a {
    text-decoration: none;
    font-weight: 350;
}

#article_content_page a:visited {
    color: rgb(151, 88, 32);
}
