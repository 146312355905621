

#lc_scratch_lesson_header_line_div {
    width: 100%;
    height: 0.38rem;
    background-color: rgb(209, 240, 236);
    
}

#lz_scratch_category_header a {
    line-height: 0.38rem;
    text-decoration: none;
    display: inline-block;
    width: 1.2rem;
    background-color: rgb(46, 149, 145);
    color: rgb(252, 243, 228);
    text-align: center;
    border-radius: 0.1rem;
    
}

#lz_scratch_category_header a:hover {
    background-color: rgb(60, 98, 100);
}

#lz_scratch_category_header span {
    font-weight: 800;
    color: rgb(46, 149, 145);
}