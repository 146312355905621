#zj_computor_navigation_bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}

#zj_computor_navigation_bar_container {
    font-size: 0.2rem;
    display: flex;
}

.zj_computor_navigation_bar_item_common {
    display: inline-block;
    width: 30%;
    padding: 0.1rem 0.5rem;
    text-align: center;
    text-decoration: none;
}

.zj_computor_navigation_bar_item_normal {
    text-decoration: none;
    color: #111111;
    background-color: white;
}

.zj_computor_navigation_bar_item_normal:hover {
    font-weight: bold;
}

.zj_computor_navigation_bar_item_selected {
    color: white;
    background-color: green;
}

.zj_computor_navigation_bar_item_selected:hover {
    font-weight: bold;
}

#zj_computor_navigation_bar_bottom_line {
    width: 100%;
    height: 0.1rem;
    background-color: #fbf6f6;
}